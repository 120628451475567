import { MASTER_DOCTOR_PREFIX, MASTER_GROUP_SPECIALTIES, MASTER_TAGS_AND_EVENTS, MASTER_APPS, MASTER_MANUALS, MASTER_FAQS, MASTER_SYMPTOM, MASTER_ORGAN, MASTER_LANG, MASTER_DEACTIVATION_REASON, MASTER_CANCEL_BOOKING_REASON,MASTER_LINE_OWNER_CODE, MASTER_CAMPAIGNS } from './../actions/types';

const initialState = {
	doctorPrefixs: [],
	groupSpecialties: [],
	tagsAndEvents: [],
	apps: [],
	manuals: [],
	faqs: [],
	symptoms: [],
	organs: [],
	langs: [],
	deactivateReasons: [],
	cancelBookingReasons: [],
	lineOwnerCode: [],
	campaigns: []
};

export default function MasterReducer(state = initialState, action) {

	switch (action.type) {
		case MASTER_DOCTOR_PREFIX:
			return { ...state, ...action.playload };
		case MASTER_GROUP_SPECIALTIES:
			return { ...state, ...action.playload };
		case MASTER_TAGS_AND_EVENTS:
			return { ...state, ...action.playload };
		case MASTER_APPS:
			return { ...state, ...action.playload };
		case MASTER_MANUALS:
			return { ...state, ...action.playload };
		case MASTER_FAQS:
			return { ...state, ...action.playload };
		case MASTER_SYMPTOM:
			return { ...state, ...action.playload };
		case MASTER_ORGAN:
			return { ...state, ...action.playload };
		case MASTER_LANG:
			return { ...state, ...action.playload };
		case MASTER_DEACTIVATION_REASON:
			return { ...state, ...action.playload };
		case MASTER_LINE_OWNER_CODE:
			return { ...state, ...action.playload };
		case MASTER_CANCEL_BOOKING_REASON:
			return { ...state, ...action.playload };
		case MASTER_CAMPAIGNS:
			return { ...state, ...action.playload };
		default:
			return { ...state };
	}
};