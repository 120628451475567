import axios from 'axios';
import Bugsnag from '@bugsnag/js'
import _ from 'lodash';
import { IS_LOADING, EMAIL_SEND_OTP, EMAIL_VERIFY_OTP, EMAIL_VERIFY_CLEAR } from './types';

export const emailSendOTP = (email) => async (dispatch) => {

	let url = '/email/otp';

	dispatch({ type: EMAIL_VERIFY_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { EMAIL_SEND_OTP: true } });
	await axios.post(url, { email: email }).then(response => {
		let message = response.data.message;
		if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
			message = response.data.errors[0].msg;
		}
		dispatch({ type: EMAIL_SEND_OTP, playload: { ref: _.has(response.data, 'data.ref') ? response.data.data.ref : null, msg: message } });
		dispatch({ type: IS_LOADING, status: false, names: { EMAIL_SEND_OTP: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { EMAIL_SEND_OTP: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const emailVerifyOTP = (ref, params) => async (dispatch) => {

	let url = `/email/otp/verify/${ref}`;

	dispatch({ type: EMAIL_VERIFY_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { EMAIL_VERIFY_OTP: true } });
	await axios.put(url, params).then(response => {
		let verify = true, message = response.data.message;
		if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
			verify = false;
			message = response.data.errors[0].msg;
		} else if (_.has(response.data, 'error')) {
			verify = false;
		}
		dispatch({ type: EMAIL_VERIFY_OTP, playload: { ref: ref, verify: verify, msg: message } });
		dispatch({ type: IS_LOADING, status: false, names: { EMAIL_VERIFY_OTP: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { EMAIL_VERIFY_OTP: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}
