import { combineReducers } from 'redux';
import LoadingReducer from './LoadingReducer';
import MasterReducer from './MasterReducer';
import HospitalReducer from './HospitalReducer';
import SmsReducer from './SmsReducer';
import EmailReducer from './EmailReducer';
import AuthReducer from './AuthReducer';
import UserReducer from './UserReducer';
import DoctorReducer from './DoctorReducer';
import PackageReducer from './PackageReducer';
import ReferralReducer from './ReferralReducer';
import BannerReducer from './BannerReducer';
import RealtimeReducer from './RealtimeReducer';
import ShortlinkReducer from './ShortlinkReducer';
import CouponReducer from './CouponReducer';
import PatientReducer from './PatientReducer';
import Feedback from './Feedback';
import Notification from './NotificationReducer';
import Insurance from './InsuranceReducer';
import ChatReducer from './ChatReducer';
import DrugReducer from './DrugReducer';
import WhitelabelReducer from './WhitelabelReducer';
import ArticleReducer from './ArticleReducer';

export default combineReducers({
	loading: LoadingReducer,
	master: MasterReducer,
	hospital: HospitalReducer,
	sms: SmsReducer,
	email: EmailReducer,
	auth: AuthReducer,
	user: UserReducer,
	doctor: DoctorReducer,
	package: PackageReducer,
	referral: ReferralReducer,
	banner: BannerReducer,
	realtime: RealtimeReducer,
	shortlink: ShortlinkReducer,
	coupon: CouponReducer,
	patient: PatientReducer,
	feedback: Feedback,
	notification: Notification,
	insurance: Insurance,
	chat: ChatReducer,
	drug: DrugReducer,
	whitelabel: WhitelabelReducer,
	article: ArticleReducer
});