import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, ARTICLE_LIST, ARTICLE_CLEAR, ARTICLE_DETAIL } from './types'

export const articleList = () => async (dispatch) => {
	let url = 'article?active=1&limit=300';
	dispatch({ type: ARTICLE_CLEAR });
	dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_LIST: false } });
	await axios.get(url).then(response => {
		if(_.has(response.data, 'data') && _.isArray(response.data.data)){
			localStorage.setItem('cacheArticle', JSON.stringify(response.data.data));
		}
		dispatch({ type: ARTICLE_LIST, playload: { dataAll: response.data.data, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_LIST: false } });
	}).catch(error => {
		dispatch({ type: ARTICLE_LIST, playload: { dataAll: [], status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_LIST: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const articleGetByCode = (refCode) => async (dispatch) => {
	let url = `article?code=${refCode}`;
	dispatch({ type: IS_LOADING, status: true, names: { ARTICLE_DETAIL: true } });
	await axios.get(url).then(response => {
		let data = {};
		if (response.data.data.length > 0) {
			data = response.data.data[0];
		}
		dispatch({ type: ARTICLE_DETAIL, playload: { detail: data } });
		dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_DETAIL: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { ARTICLE_DETAIL: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}
