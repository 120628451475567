import { CHAT_LIST, CHAT_SEND_MESSAGE, CHAT_BY_PATIENT_LIST } from './../actions/types';

const initialState = {
	data: {},
	dataAll: [],
	lists: []
};

export default function ChatReducer(state = initialState, action) {

	switch (action.type) {
		case CHAT_LIST:
			return { ...state, ...action.playload };
		case CHAT_SEND_MESSAGE:
			return { ...state, ...action.playload };
		case CHAT_BY_PATIENT_LIST:
			return { ...state, ...action.playload };
		default:
			return { ...state };
	}
};