import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, BANNER_LIST, BANNER_CLEAR } from './types'

export const bannerList = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheBanners');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData)) && JSON.parse(cacheData).length > 0){
		dispatch({ type: BANNER_LIST, playload: { dataAll: JSON.parse(cacheData), status: 'success' } });
	} else {
		let url = `banners`;
		dispatch({ type: BANNER_CLEAR });
		dispatch({ type: IS_LOADING, status: false, names: { BANNER_LIST: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheBanners', JSON.stringify(response.data.data));
			}
			dispatch({ type: BANNER_LIST, playload: { dataAll: response.data.data, status: 'success' } });
			dispatch({ type: IS_LOADING, status: false, names: { BANNER_LIST: false } });
		}).catch(error => {
			dispatch({ type: BANNER_LIST, playload: { dataAll: [], status: 'success' } });
			dispatch({ type: IS_LOADING, status: false, names: { BANNER_LIST: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}
