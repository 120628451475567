import { DOCTOR_LIST, DOCTOR_LIST_RECOMMEND, DOCTOR_LIST_FAVORITE, DOCTOR_GET_BY_CODE, DOCTOR_CHECK_ONLINE, DOCTOR_CLEAR } from './../actions/types';

const initialState = {
	data: null,
	dataAll: [],
	dataRecommends: [],
	dataFavorites: [],
	dataFavoritesLoaded: false
};

export default function DoctorReducer(state = initialState, action) {

	switch (action.type) {
		case DOCTOR_LIST:
			return { ...state, ...action.playload };
		case DOCTOR_LIST_RECOMMEND:
			return { ...state, ...action.playload };
		case DOCTOR_LIST_FAVORITE:
			return { ...state, ...action.playload, dataFavoritesLoaded: true };
		case DOCTOR_GET_BY_CODE:
			return { ...state, ...action.playload };
		case DOCTOR_CHECK_ONLINE:
			return { ...state, isOnline: action.isOnline, status: action.status };
		case DOCTOR_CLEAR:
			return { ...state, data: null, status: undefined };
		default:
			return { ...state };
	}
};