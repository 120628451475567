import { REFERRAL_GET_BY_CODE, REFERRAL_GET_BY_CODE_NAV, REFERRAL_GET_BY_CODE_CHAT, REFERRAL_GET_REVIEW, REFERRAL_GET_HISTORY, REFERRAL_GET_DRUG_HISTORY, REFERRAL_GET_APPOINTMENT, REFERRAL_GET_DOCTOR_BOOKING, REFERRAL_CHECK_BOOKING, REFERRAL_BOOKING, REFERRAL_UPDATE, REFERRAL_CANCEL_BOOKING, REFERRAL_CLEAR, REFERRAL_CHAT_CLEAR, REFERRAL_CHECK_BOOKING_AVAILABLE,REFERRAL_GET_TODAY } from './../actions/types';

const initialState = {
	data: {},
	dataNav: {},
	dataChat: {},
	dataReviews: [],
	dataHistories: [],
	dataDrugHistories: [],
	dataAppointment: {},
	dataDoctorBooking: null,
	dataCheckBooking: null,
	dataCancelBooking: null,
	dataToday: [],
	dataTodayLoaded: false
};

export default function ReferralReducer(state = initialState, action) {

	switch (action.type) {
		case REFERRAL_BOOKING:
			return { ...state, ...action.playload };
		case REFERRAL_GET_BY_CODE:
			return { ...state, ...action.playload };
		case REFERRAL_GET_BY_CODE_NAV:
			return { ...state, ...action.playload };
		case REFERRAL_GET_BY_CODE_CHAT:
			return { ...state, ...action.playload };
		case REFERRAL_GET_REVIEW:
			return { ...state, ...action.playload };
		case REFERRAL_GET_HISTORY:
			return { ...state, ...action.playload };
		case REFERRAL_GET_DRUG_HISTORY:
			return { ...state, ...action.playload };
		case REFERRAL_GET_APPOINTMENT:
			return { ...state, ...action.playload };
		case REFERRAL_GET_DOCTOR_BOOKING:
			return { ...state, ...action.playload };
		case REFERRAL_CHECK_BOOKING:
			return { ...state, ...action.playload };
		case REFERRAL_UPDATE:
			return { ...state, ...action.playload };
		case REFERRAL_CANCEL_BOOKING:
			return { ...state, ...action.playload };
		case REFERRAL_CHECK_BOOKING_AVAILABLE:
			return { ...state, ...action.playload };
		case REFERRAL_GET_TODAY:
			return { ...state, ...action.playload, dataTodayLoaded: true };
		case REFERRAL_CLEAR:
			return {
				...state,
				data: {},
				dataDoctorBooking: null,
				dataCheckBooking: null,
				dataCancelBooking: null,
				isUpdate: undefined,
				doctorIdleStatus: undefined,
				status: undefined
			};
		case REFERRAL_CHAT_CLEAR:
			return { ...state, dataChat: {} };
		default:
			return { ...state };
	}
};