import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, CHAT_LIST, CHAT_SEND_MESSAGE, CHAT_BY_PATIENT_LIST } from './types'

export const chatList = (refCode, docCode) => async (dispatch) => {
	//hide loading
	let url = `chat?role=patient&refCode=${refCode}&userCode=${docCode}&limit=100`;
	let columns = ['role', 'message', 'read', 'type', 'fileType', 'createdAt'];
	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}
	dispatch({ type: IS_LOADING, status: false, names: { CHAT_LIST: false } });
	await axios.get(url).then(response => {
		dispatch({ type: CHAT_LIST, playload: { dataAll: response.data.data } });
		dispatch({ type: IS_LOADING, status: false, names: { CHAT_LIST: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { CHAT_LIST: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const chatSendMessage = (refCode, patCode, message, type = 'message', fileType = 'image') => async (dispatch) => {
	let url = 'chat';
	let params = { role: 'patient', type: type, refCode: refCode, code: patCode, message: message }
	if (type === 'file') {
		params.fileType = fileType;
	}
	await axios.post(url, params).then(response => {
		let sent = true;
		if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
			sent = false;
		} else if (_.has(response.data, 'error')) {
			sent = false;
		}
		dispatch({ type: CHAT_SEND_MESSAGE, playload: { data: !_.isUndefined(response.data.data) ? response.data.data : {}, sent: sent } });
	}).catch(error => {
		dispatch({ type: CHAT_SEND_MESSAGE, playload: { data: {}, sent: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const chatRead = (refCode, patCode) => async () => {
	let url = `/chat/read/${refCode}`;
	await axios.put(url, { role: 'patient', userCode: patCode }).then(() => {

	}).catch(error => {
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const chatByPatientList = (patCode) => async (dispatch) => {
    //hide loading
	let url = `/chat/byPatient/${patCode}`;
	dispatch({ type: IS_LOADING, status: false, names: { CHAT_BY_PATIENT_LIST: false } });
	await axios.get(url).then(response => {
		dispatch({ type: CHAT_BY_PATIENT_LIST, playload: { lists: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { CHAT_BY_PATIENT_LIST: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { CHAT_BY_PATIENT_LIST: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}