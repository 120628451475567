import {
	ARTICLE_LIST,
	ARTICLE_CLEAR,
	ARTICLE_DETAIL
} from './../actions/types';

const initialState = {
	dataAll: [],
	detail: {}
};

export default function ArticleReducer(state = initialState, action) {

	switch (action.type) {
		case ARTICLE_LIST:
			return { ...state, ...action.playload };
		case ARTICLE_DETAIL:
			return { ...state, ...action.playload };
		case ARTICLE_CLEAR:
			return { ...state, status: undefined };
		default:
			return { ...state };
	}
};