import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translations.json';
import translationTH from './locales/th/translations.json';
import translationMS from './locales/ms/translations.json';
import translationID from './locales/id/translations.json';
import translationAR from './locales/ar/translations.json';
import translationQA from './locales/qa/translations.json';
import translationKW from './locales/kw/translations.json';
import translationAE from './locales/ae/translations.json';
import translationBH from './locales/bh/translations.json';
import translationLA from './locales/la/translations.json';
import translationZH from './locales/zh/translations.json';
import translationMY from './locales/my/translations.json';
import translationKR from './locales/kr/translations.json';
import translationJP from './locales/jp/translations.json';

const resources = {
	en: { translation: translationEN },
	th: { translation: translationTH },
	ms: { translation: translationMS },
	id: { translation: translationID },
	ar: { translation: translationAR },
	qa: { translation: translationQA },
	kw: { translation: translationKW },
	ae: { translation: translationAE },
	bh: { translation: translationBH },
	la: { translation: translationLA },
	zh: { translation: translationZH },
	my: { translation: translationMY },
	kr: { translation: translationKR },
	jp: { translation: translationJP }
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		debug: process.env.REACT_APP_DEBUG === 'true',
		initImmediate: false,
		fallbackLng: 'th',
		resources,
		interpolation: {
			escapeValue: false
		},
		react: {
			useSuspense: false
		}
	});
export default i18n;