import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, AUTH_SIGN_IN, AUTH_SIGN_OUT, AUTH_REGISTER, AUTH_CHANGE_PASSWORD, AUTH_FORGOT_PASSWORD, AUTH_FORGOT_PASSWORD_VERIFY_OTP, AUTH_FORGOT_PASSWORD_CHANGE, AUTH_CLEAR, AUTH_ADD_CONTACT, AUTH_ADD_CONTACT_VERIFY, AUTH_ADD_CONTACT_UPDATE, AUTH_CHANGE_CONTACT_DEFAULT, AUTH_CLOST_ACCOUNT, AUTH_GET_FAMILY } from './types'
import { LINE_CLIENT_ID, LINE_CLIENT_SECRET, LINE_REDIRECT_URI } from '../configs'
import { decrypt } from '../helpers/utilities'
import { updateRealtime } from './RealtimeAction'

export const authSignIn = (params) => async (dispatch) => {
	if(localStorage.getItem('cacheToken')){
		localStorage.removeItem('cacheToken');
	}
	if(localStorage.getItem('cacheTokenData')){
		localStorage.removeItem('cacheTokenData');
	}
	let url = '/accounts/patientlogin';
	dispatch({ type: AUTH_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_SIGN_IN: true } });
	await axios.post(url, params).then(response => {
		let profile = {}, message = '';
		if (_.has(response.data, 'profiles') && response.data.profiles.length > 0) {
			let data = response.data.profiles.filter(item => item.group === 'patient');
			if (data.length > 0) {
				profile = data[0].profile;
				Bugsnag.setUser(profile.patCode, profile.email, profile.firstName + ' ' + profile.lastName);
			}
		} else {
			if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
				message = response.data.errors[0].msg;
			} else {
				message = response.data.message;
			}
		}
		const playload = {
			loggedIn: _.has(response.data, 'profiles') && response.data.profiles.length > 0 && _.has(response.data, 'accessToken'),
			loginWith: params.type,
			accessToken: _.has(response.data, 'accessToken') ? response.data.accessToken : null,
			profile: profile,
			isAccess: false,
			message: message,
			status: 'success'
		}
		dispatch({ type: AUTH_SIGN_IN, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_SIGN_IN: false } });
	}).catch(err => {
    let error = err;
    const message = 'This account is not available. Please contact support';
		const playload = {
			loggedIn: false,
			loginWith: params?.type,
			accessToken: null,
			profile: {},
			isAccess: false,
			message,
			status: 'success'
		};
		dispatch({ type: AUTH_SIGN_IN, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_SIGN_IN: false } });
    if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
      if (_.isUndefined(error.response)) { error = Error(`502 API at ${url}`); }
      Bugsnag.notify(error);
    }
	});
}

export const authSignOut = () => (dispatch) => {
	if(localStorage.getItem('currentVisitDate')!==''){
		localStorage.removeItem('currentVisitDate');
	}	
	if(localStorage.getItem('currentVisitTime')!==''){
		localStorage.removeItem('currentVisitTime');
	}
	if(localStorage.getItem('currentMedicalProblem')!==''){
		localStorage.removeItem('currentMedicalProblem');
	}
	if(localStorage.getItem('currentHaveDrugAllergyHistory')!==''){
		localStorage.removeItem('currentHaveDrugAllergyHistory');
	}
	if(localStorage.getItem('currentHaveCongenitalDisease')!==''){
		localStorage.removeItem('currentHaveCongenitalDisease');
	}
	if(localStorage.getItem('currentCongenitalDisease')!==''){
		localStorage.removeItem('currentCongenitalDisease');
	}	
	if(localStorage.getItem('cacheToken')){
		localStorage.removeItem('cacheToken');
	}
	if(localStorage.getItem('cacheTokenData')){
		localStorage.removeItem('cacheTokenData');
	}
	dispatch(updateRealtime('remove-cookie', true));
	dispatch({ type: AUTH_SIGN_OUT });
}

export const authClear = (type = AUTH_CLEAR) => (dispatch) => {
	dispatch({ type: type });
}

export const authValidateToken = async (token) => {
  //need change loading
	const cacheToken = localStorage.getItem('cacheToken');
	const cacheTokenData = localStorage.getItem('cacheTokenData');
	if(cacheToken && cacheToken !== '' && cacheToken !== 'null' && cacheTokenData && token === cacheToken && JSON.parse(cacheTokenData)){
		return JSON.parse(cacheTokenData);
	} else {
		let url = `/accounts/checkpatient/${token}`;
		return axios.get(url).then(response => {
			if(_.has(response, 'data.profiles')&&_.isArray(response.data.profiles)){
				const user = response.data.profiles.filter((o)=>{ return o.group === 'patient';});
				if(user.length>0&&_.has(user[0], 'profile.patId')&&_.has(user[0], 'profile.patCode')){
					const currentDomain = window.location.hostname;
					const params = {
						userId: user[0].profile.patId,
						userCode: user[0].profile.patCode,
						userRole: "patient",
						system_channel: currentDomain,
						domain: currentDomain
					}
					let urlLogging = 'logging/lastLogin';
					axios.post(urlLogging, params).then(resLogging => {}).catch(error => {});
				}
			}
			localStorage.setItem('cacheToken', token);
			localStorage.setItem('cacheTokenData', JSON.stringify(response.data));
			return response.data;
		});
	}
}

export const authAccessToken = (data, cookie) => async (dispatch) => {
	let playload = {
		loggedIn: true,
		loginWith: cookie.loginWith,
		accessToken: cookie.accessToken,
		profile: {},
		isAccess: true,
		doctorHistories: []
	}
	dispatch({ type: AUTH_CLEAR });
	dispatch({ type: IS_LOADING, status: true });
	if(_.has(data, 'doctorHistories')){
		playload.doctorHistories = data.doctorHistories;
	}
	if (_.has(cookie, 'CARE_RECIPIENT')) {
		const decode = decrypt(cookie.CARE_RECIPIENT);
		let url = `/patients?code=${decode.patCode}`;
		await axios.get(url).then(response => {
			if (_.has(response.data, 'data') && response.data.data.length > 0) {
				const patient = response.data.data[0];
				Bugsnag.setUser(patient.patCode + '-' + patient.mainAccount, patient.email, patient.firstName + ' ' + patient.lastName);
				playload.profile = patient;
				playload.status = 'success';
				dispatch({ type: AUTH_SIGN_IN, playload: playload });
				dispatch({ type: IS_LOADING, status: false });
				const accActive = localStorage.getItem('active-account');
				localStorage.setItem('active-account', patient?.patCode);
				if (accActive && patient?.patCode !== accActive && _.has(patient,'patCode')) {
					setTimeout(() => {
						window.location.reload();
					}, 2000);
				}else{
					if(!_.has(patient,'patCode')){
						dispatch(updateRealtime('remove-cookie', true));
						dispatch({ type: AUTH_SIGN_OUT });
					}
				}
			}
		}).catch(error => {
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	} else {
		let profile = {};
		if (_.has(data, 'profiles') && data.profiles.length > 0) {
			let profiles = data.profiles.filter(item => item.group === 'patient');
			if (profiles.length > 0) {
				profile = profiles[0].profile;
				Bugsnag.setUser(profile.patCode, profile.email, profile.firstName + ' ' + profile.lastName);
			}
		}
		playload.profile = profile;
		playload.status = 'success';
		dispatch({ type: AUTH_SIGN_IN, playload: playload });
		dispatch({ type: IS_LOADING, status: false });
		const accActive = localStorage.getItem('active-account');
		localStorage.setItem('active-account', profile?.patCode);
		if (accActive && profile?.patCode !== accActive && _.has(profile,'patCode')) {
			setTimeout(() => {
				window.location.reload();
			}, 2000);
		}else{
			if(!_.has(profile,'patCode')){
				dispatch(updateRealtime('remove-cookie', true));
				dispatch({ type: AUTH_SIGN_OUT });
			}
		}
	}
}

export const authRegister = (params) => async (dispatch) => {
	let url = '/accounts/register';
	dispatch({ type: AUTH_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_REGISTER: true } });
	await axios.post(url, params).then(response => {
		let playload = {
			status: 'success'
		};
		if (_.has(response.data, 'profiles') && response.data.profiles.length > 0) {
			let data = response.data.profiles.filter(item => item.group === 'patient');
			if (data.length > 0) {
				playload.isRegisterSuccess = true;
				playload.profile = data[0].profile;
			}
		} else {
			playload.isRegisterSuccess = false;
			if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
				playload.message = response.data.errors[0].msg;
			} else {
				playload.message = response.data.message
			}
		}
		dispatch({ type: AUTH_REGISTER, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_REGISTER: false } });
	}).catch(error => {
		dispatch({ type: AUTH_REGISTER, playload: { status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_REGISTER: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const authChangePassword = (token, oldPassword, newPassword) => async (dispatch) => {
	let url = '/accounts/changePassword';
	const params = {
		accessToken: token,
		oldPassword: oldPassword,
		newPassword: newPassword
	}
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_CHANGE_PASSWORD: true } });
	await axios.put(url, params).then(response => {
		let playload = {}
		if (_.has(response.data, 'message') && response.data.message === 'Success') {
			playload.isChangePasswordSuccess = true;
		} else {
			playload.isChangePasswordSuccess = false;
			if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
				playload.message = response.data.errors[0].msg;
			} else {
				playload.message = response.data.message
			}
		}
		if (playload.message === 'Incorrect username or password.') {
			playload.message = 'Incorrect password';
		}
		dispatch({ type: AUTH_CHANGE_PASSWORD, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_CHANGE_PASSWORD: false } });
	}).catch(error => {
		dispatch({ type: AUTH_CHANGE_PASSWORD, playload: { isChangePasswordSuccess: false, message: error.response.data.message } });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_CHANGE_PASSWORD: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const authForgotPassword = (username) => async (dispatch) => {
	let url = '/accounts/forgotPassword';
	const params = {
		username: username
	}
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_FORGOT_PASSWORD: true } });
	await axios.put(url, params).then(response => {
		let message = response.data.message;
		if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
			message = response.data.errors[0].msg;
		}
		dispatch({ type: AUTH_FORGOT_PASSWORD, playload: { ref: _.has(response.data, 'data.ref') ? response.data.data.ref : null, msg: message } });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_FORGOT_PASSWORD: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_FORGOT_PASSWORD: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const authForgotPasswordVerifyOTP = (ref, params) => async (dispatch) => {
	let url = `/accounts/forgotPassword/otp/verify/${ref}`;
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_FORGOT_PASSWORD_VERIFY_OTP: true } });
	await axios.put(url, params).then(response => {
		let verify = true, message = response.data.message;
		if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
			verify = false;
			message = response.data.errors[0].msg;
		} else if (_.has(response.data, 'error')) {
			verify = false;
		}
		dispatch({ type: AUTH_FORGOT_PASSWORD_VERIFY_OTP, playload: { ref: ref, verify: verify, msg: message } });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_FORGOT_PASSWORD_VERIFY_OTP: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_FORGOT_PASSWORD_VERIFY_OTP: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const authForgotPasswordChange = (params) => async (dispatch) => {
	let url = '/accounts/forgotPasswordChange';
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_FORGOT_PASSWORD_CHANGE: true } });
	await axios.put(url, params).then(response => {
		let playload = {}
		if (_.has(response.data, 'user') && response.data.message === 'Success') {
			playload.isChangePasswordSuccess = true;
		} else {
			playload.isChangePasswordSuccess = false;
			if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
				playload.message = response.data.errors[0].msg;
			} else {
				playload.message = response.data.message
			}
		}
		dispatch({ type: AUTH_FORGOT_PASSWORD_CHANGE, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_FORGOT_PASSWORD_CHANGE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_FORGOT_PASSWORD_CHANGE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const authAddContect = (patCode, value, type = 'mobile', channel = 'daz') => async (dispatch) => {
	let url = type === 'mobile' ? '/accounts/addMobile' : '/accounts/addEmail';
	let params = {
		roleType: 'patient',
		channel: channel,
		code: patCode
	}
	if (type === 'mobile') {
		params.newMobile = value;
	} else {
		params.newEmail = value;
	}
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_ADD_CONTACT: true } });
	await axios.post(url, params).then(response => {
		let playload = {};
		if (_.has(response.data, 'data.ref')) {
			playload.isAddContact = true;
			playload.authRef = response.data.data.ref;
		} else {
			playload.isAddContact = false;
			if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
				playload.message = response.data.errors[0].msg;
			} else {
				playload.message = response.data.message
			}
		}
		dispatch({ type: AUTH_ADD_CONTACT, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const authAddContactVerify = (refCode, value, OTP, type = 'mobile', channel = 'daz') => async (dispatch) => {
	let url = type === 'mobile' ? `/accounts/addMobile/otp/verify/${refCode}` : `/accounts/addEmail/otp/verify/${refCode}`;
	let params = {
		channel: channel,
		otpNumber: OTP
	}
	if (type === 'mobile') {
		params.phone = value;
	} else {
		params.email = value;
	}
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_ADD_CONTACT_VERIFY: true } });
	await axios.put(url, params).then(response => {
		let playload = {
			isAddContactVerify: true,
			message: response.data.message
		};
		if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
			playload.isAddContactVerify = false;
			playload.message = response.data.errors[0].msg;
		} else if (_.has(response.data, 'error')) {
			playload.isAddContactVerify = false;
		}
		dispatch({ type: AUTH_ADD_CONTACT_VERIFY, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT_VERIFY: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT_VERIFY: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const authAddContactUpdate = (patCode, value, type = 'mobile', channel = 'daz') => async (dispatch) => {
	let url = type === 'mobile' ? '/accounts/addMobileConfirm' : '/accounts/addEmailConfirm';
	let params = {
		roleType: 'patient',
		channel: channel,
		code: patCode
	}
	if (type === 'mobile') {
		params.mobile = value;
	} else {
		params.email = value;
	}
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_ADD_CONTACT_UPDATE: true } });
	await axios.post(url, params).then(response => {
		let playload = {
			isAddContactUpdate: true,
			message: response.data.message
		};
		if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
			playload.isAddContactUpdate = false;
			playload.message = response.data.errors[0].msg;
		} else if (_.has(response.data, 'error')) {
			playload.isAddContactUpdate = false;
		}
		dispatch({ type: AUTH_ADD_CONTACT_UPDATE, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT_UPDATE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_ADD_CONTACT_UPDATE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const authChangeContactDefault = (patCode, value, currentPassword, type = 'mobile') => async (dispatch) => {
	let url = type === 'mobile' ? '/accounts/changeMainMobile' : '/accounts/changeMainEmail';
	let params = {
		roleType: 'patient',
		code: patCode,
		currentPassword: currentPassword
	}
	if (type === 'mobile') {
		params.mobile = value;
	} else {
		params.email = value;
	}
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_CHANGE_CONTACT_DEFAULT: true } });
	await axios.post(url, params).then(response => {
		let playload = {
			isChangeContactDefault: true,
			message: response.data.message
		};
		if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
			playload.isChangeContactDefault = false;
			playload.message = response.data.errors[0].msg;
		} else if (_.has(response.data, 'error')) {
			playload.isChangeContactDefault = false;
		}
		if (playload.message === 'Incorrect username or password.') {
			playload.message = 'Incorrect password';
		}
		dispatch({ type: AUTH_CHANGE_CONTACT_DEFAULT, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_CHANGE_CONTACT_DEFAULT: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_CHANGE_CONTACT_DEFAULT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const authCloseAccount = (patCode, reason, currentPassword) => async (dispatch) => {
	let url = '/accounts/close';
	const params = {
		roleType: 'patient',
		code: patCode,
		reason: reason,
		currentPassword: currentPassword
	}
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_CLOST_ACCOUNT: true } });
	await axios.post(url, params).then(response => {
		let playload = {
			isCloseAccount: true,
			message: response.data.message
		};
		if (_.has(response.data, 'errors') && response.data.errors.length > 0) {
			playload.isCloseAccount = false;
			playload.message = response.data.errors[0].msg;
		} else if (_.has(response.data, 'error')) {
			playload.isCloseAccount = false;
		}
		if (playload.message === 'Incorrect username or password.') {
			playload.message = 'Incorrect password';
		}
		dispatch({ type: AUTH_CLOST_ACCOUNT, playload: playload });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_CLOST_ACCOUNT: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_CLOST_ACCOUNT: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const authSignInWithLINE = (code) => async (dispatch) => {
	let url = 'https://api.line.me/oauth2/v2.1/token';
	let params = new URLSearchParams({
		grant_type: 'authorization_code',
		code: code,
		client_id: LINE_CLIENT_ID,
		client_secret: LINE_CLIENT_SECRET,
		redirect_uri: LINE_REDIRECT_URI
	}).toString();
	dispatch({ type: AUTH_CLEAR });
	dispatch({ type: IS_LOADING, status: true, names: { AUTH_SIGN_IN_WITH_LINE: true } });
	await axios.post(url, params, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	}).then(responseToken => {
		authValidateToken(responseToken.data.access_token).then(response => {
			dispatch(authAccessToken(response, { accessToken: responseToken.data.access_token, loginWith: 'line' }))
		}).catch(() => {
			dispatch({ type: AUTH_SIGN_IN, playload: { loggedIn: false, loggedInWithLine: true, status: 'success' } });
		})
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_SIGN_IN_WITH_LINE: false } });
	}).catch(error => {
		dispatch({ type: AUTH_SIGN_IN, playload: { loggedIn: false, loggedInWithLine: false, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_SIGN_IN_WITH_LINE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const updateSignal = async (code, signalId) => {
	let url = `/patients/${code}`;
	return new Promise((reslove) => {
		axios.put(url, { signalId: signalId }).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const getDoctorSignal = async (code) => {
	const codes = code.split('-');
	if (codes.length > 1) {
		code = codes[0];
	}
	let url = `/doctors?codes[0]=${code}`;
	return new Promise(reslove => {
		axios.get(url).then(response => {
			if (_.has(response, 'data.data') && _.isArray(response.data.data) && response.data.data.length > 0 && _.has(response.data.data[0], 'signalId')) {
				reslove(response.data.data[0].signalId);
			} else {
				reslove('');
			}
		}).catch(() => {
			reslove('');
		});
	});
}

export const makeAuthByToken = async (params) => {
	let url = `/auth/token`;
	return new Promise((reslove) => {
		axios.post(url, params).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const checkMultiProfile = async (mobile) => {
	let url = `/accounts/checkMultiProfile/${mobile}`;
	return new Promise((reslove) => {
		axios.get(url).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const sendOtpSyncProfile = async (params) => {
	let url = `/sms/otpSyncProfile`;
	return new Promise((reslove) => {
		axios.post(url, params).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const vserifyOtpSyncProfile = async (ref,params) => {
	let url = `/sms/otp/verify/${ref}`;
	return new Promise((reslove) => {
		axios.put(url, params).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const multiProfileAction = async (patCode,params) => {
	let url = `/accounts/multiProfileAction/${patCode}`;
	return new Promise((reslove) => {
		axios.put(url,params).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const checkReferralPharmacyStatus = async (refCode) => {
	let url = `/referrals?code=${refCode}`;
	let columns = ['medOrderStatus'];
	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}
	return new Promise((reslove) => {
		axios.get(url).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const searchPlaceInMaps = (search,key) => {
	let url = `/googlemap/search`;
	return new Promise((reslove) => {
		axios.post(url,{search,key}).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const checkReferralDoctorInRoom = async (refCode) => {
	let url = `/referrals?code=${refCode}`;
	let columns = ['doctorWaitInRoom','patientWaitInRoom'];
	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}
	return new Promise((reslove) => {
		axios.get(url).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const getCompanyByCode = async (companyCode) => {
	let url = `/companies?code=${companyCode}`;
	return new Promise((reslove) => {
		axios.get(url).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const checkMyCouponByPatCode = async (patCode) => {
	let url = `/coupons/checkByPatCode/${patCode}`;
	return new Promise((reslove) => {
		axios.get(url).then(response => {
			reslove(response.data);
		}).catch(error => {
			reslove({});
		});
	});
}

export const authGetFamily = (query) => async (dispatch) => {
	let url = `patients?${query}`.replace('?&', '?');
	let columns = ['patCode', 'firstName', 'lastName', 'gender', 'birthDate', 'mainAccount', 'passportNumber','congenitalDisease','drugAllergyHistory'];
	if (columns.length > 0) {
		url += '&attrType=custom';
		columns.forEach((column, key) => {
			url += `&attributes[${key}]=${column}`;
		})
	}
	dispatch({ type: AUTH_GET_FAMILY });
	dispatch({ type: IS_LOADING, status: false, names: { AUTH_GET_FAMILY: false } });
	await axios.get(url).then(response => {
		dispatch({ type: AUTH_GET_FAMILY, playload: { family: response.data.data, status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_GET_FAMILY: false } });
	}).catch(error => {
		dispatch({ type: AUTH_GET_FAMILY, playload: { family: [], status: 'success' } });
		dispatch({ type: IS_LOADING, status: false, names: { AUTH_GET_FAMILY: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}