import { PATIENT_GET_BY_CODE, PATIENT_SEARCH, PATIENT_CREATE, PATIENT_UPDATE, PATIENT_CLEAR, PATIENT_GET_HEALTH_RECORD, PATIENT_GET_DOCUMENT } from './../actions/types';

const initialState = {
	data: {},
	dataAll: [],
	dataSearch: [],
	dataHealthRecords: [],
	dataDocumentFiles: []
};

export default function PatientReducer(state = initialState, action) {

	switch (action.type) {
		case PATIENT_GET_BY_CODE:
			return { ...state, ...action.playload };
		case PATIENT_SEARCH:
			return { ...state, ...action.playload };
		case PATIENT_CREATE:
			return { ...state, ...action.playload };
		case PATIENT_UPDATE:
			return { ...state, ...action.playload };
		case PATIENT_GET_HEALTH_RECORD:
			return { ...state, ...action.playload };
		case PATIENT_GET_DOCUMENT:
			return { ...state, ...action.playload };
		case PATIENT_CLEAR:
			return { ...state, isDuplicate: undefined, status: undefined };
		default:
			return { ...state };
	}
};