import { REALTIME_DATA,REALTIME_SIGNAL,REALTIME_SIGNAL_DOCTOR_ONLINE,REALTIME_SIGNAL_UPDATE } from './../actions/types';

const initialState = {
	name: '',
	status: false,
	data: {},
	signalStatus: '',
	doctors: [],
	updateSignal: false
};

export default function RealtimeReducer(state = initialState, action) {

	switch (action.type) {
		case REALTIME_DATA:
			return { ...state, ...action.playload };
		case REALTIME_SIGNAL:
			return { ...state, ...action.playload };
		case REALTIME_SIGNAL_DOCTOR_ONLINE:
			return { ...state, ...action.playload };
		case REALTIME_SIGNAL_UPDATE:
			return { ...state, ...action.playload };
		default:
			return { ...state };
	}
}