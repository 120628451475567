import _ from "lodash";
const [, , subdomain] = window.location.hostname.split('.').reverse();

export const MARKETPLACE_URL = process.env.REACT_APP_MARKETPLACE_URL;
export const COOKIE_SECURE = process.env.REACT_APP_COOKIE_SECURE;
export const DEFAULT_CREATE_BY_APP = process.env.REACT_APP_DEFAULT_CREATE_BY_APP;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const DEFAULT_SUB_DOMAIN = process.env.REACT_APP_DEFAULT_SUB_DOMAIN;
export const SUB_DOMAIN = _.isUndefined(subdomain) ? DEFAULT_SUB_DOMAIN : subdomain;
export const SHORT_LINK_URL = process.env.REACT_APP_SHORT_LINK_URL;
export const PAYMENT_URL = process.env.REACT_APP_PAYMENT_URL;
export const PAYMENT_METHOD = process.env.REACT_APP_PAYMENT_METHOD;
export const SIGNAL_HOST = process.env.REACT_APP_SIGNAL_HOST;
export const SIGNAL_PORT = process.env.REACT_APP_SIGNAL_PORT;
export const SIGNAL_PATH = process.env.REACT_APP_SIGNAL_PATH;
export const DOMAIN_SERVER_MEETING = process.env.REACT_APP_DOMAIN_SERVER_MEETING;
export const WAITING_PERIOD_CALL = process.env.REACT_APP_WAITING_PERIOD_CALL;
export const WAITING_REQUEST_CALL_NOW = process.env.REACT_APP_WAITING_REQUEST_CALL_NOW;
export const WAITING_PHARMACY = process.env.REACT_APP_WAITING_PHARMACY;
export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const LINE_CLIENT_ID = process.env.REACT_APP_LINE_CLIENT_ID;
export const LINE_CLIENT_SECRET = process.env.REACT_APP_LINE_CLIENT_SECRET;
export const LINE_NONCE = process.env.REACT_APP_LINE_NONCE;
export const LINE_REDIRECT_URI = process.env.REACT_APP_LINE_REDIRECT_URI;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
export const APPLE_REDIRECT_URI = process.env.REACT_APP_APPLE_REDIRECT_URI;
export const EKYC_URL = process.env.REACT_APP_EKYC_URL;
export const EKYC_CHANNEL = process.env.REACT_APP_EKYC_CHANNEL;
export const EKYC_CALLBACK_URL = process.env.REACT_APP_EKYC_CALLBACK_URL;
export const API_PARTNER_URL = process.env.REACT_APP_API_PARTNER_URL;
export const API_PARTNER_ACCESS_TOKEN = process.env.REACT_APP_API_PARTNER_ACCESS_TOKEN;
export const USE_EKYC = process.env.REACT_APP_USE_EKYC;
export const EXTEND_TIME = 60*1;
export const PROMOTE_GROUP_SPECIALTIES = process.env.REACT_APP_PROMOTE_GROUP_SPECIALTIES.split(',');
export const DAZ_DOMAINS = process.env.REACT_APP_DAZ_DOMAIN.split(',');
export const DRUG_DELIVERY_DISTANCE = [
  { distance: 1, price: 50 },
  { distance: 2, price: 60 },
  { distance: 3, price: 70 },
  { distance: 4, price: 80 },
  { distance: 5, price: 90 },
  { distance: 6, price: 100 },
  { distance: 7, price: 110 },
  { distance: 8, price: 120 },
  { distance: 9, price: 130 },
  { distance: 10, price: 140 },
  { distance: 11, price: 150 },
  { distance: 12, price: 160 },
  { distance: 13, price: 170 },
  { distance: 14, price: 180 },
  { distance: 15, price: 190 },
  { distance: 16, price: 200 },
  { distance: 17, price: 210 },
  { distance: 18, price: 220 },
  { distance: 19, price: 230 },
  { distance: 20, price: 240 },
  { distance: 21, price: 250 },
  { distance: 22, price: 260 },
  { distance: 23, price: 270 },
  { distance: 24, price: 280 },
  { distance: 25, price: 290 },
  { distance: 26, price: 300 },
  { distance: 27, price: 310 },
  { distance: 28, price: 320 },
  { distance: 29, price: 330 },
  { distance: 30, price: 340 },
  { distance: 31, price: 350 },
  { distance: 32, price: 360 },
  { distance: 33, price: 370 },
  { distance: 34, price: 380 },
  { distance: 35, price: 390 }
];