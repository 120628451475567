import { SMS_SEND_OTP, SMS_VERIFY_CLEAR, SMS_VERIFY_OTP } from '../actions/types';

const initialState = {
	ref: null
};

export default function SmsReducer(state = initialState, action) {

	switch (action.type) {
		case SMS_SEND_OTP:
			return { ...state, ...action.playload };
		case SMS_VERIFY_OTP:
			return { ...state, ...action.playload };
		case SMS_VERIFY_CLEAR:
			return {
				...state,
				ref: null,
				msg: undefined
			};
		default:
			return { ...state };
	}
}