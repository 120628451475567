export const IS_LOADING = 'IS_LOADING';
export const USER_CHECK_PROFILE = 'USER_CHECK_PROFILE';
export const USER_CLEAR = 'USER_CLEAR';
export const SMS_SEND_OTP = 'SMS_SEND_OTP';
export const SMS_VERIFY_OTP = 'SMS_VERIFY_OTP';
export const SMS_VERIFY_CLEAR = 'SMS_VERIFY_CLEAR';
export const EMAIL_SEND_OTP = 'EMAIL_SEND_OTP';
export const EMAIL_VERIFY_OTP = 'EMAIL_VERIFY_OTP';
export const EMAIL_VERIFY_CLEAR = 'EMAIL_VERIFY_CLEAR';
export const AUTH_SIGN_IN = 'AUTH_SIGN_IN';
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_REGISTER = 'AUTH_REGISTER';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORGOT_PASSWORD_VERIFY_OTP = 'AUTH_FORGOT_PASSWORD_VERIFY_OTP';
export const AUTH_FORGOT_PASSWORD_CHANGE = 'AUTH_FORGOT_PASSWORD_CHANGE';
export const AUTH_ADD_CONTACT = 'AUTH_ADD_CONTACT';
export const AUTH_ADD_CONTACT_VERIFY = 'AUTH_ADD_CONTACT_VERIFY';
export const AUTH_ADD_CONTACT_UPDATE = 'AUTH_ADD_CONTACT_UPDATE';
export const AUTH_CHANGE_CONTACT_DEFAULT = 'AUTH_CHANGE_CONTACT_DEFAULT';
export const AUTH_CLEAR = 'AUTH_CLEAR';
export const AUTH_CLEAR_CONTACT_VERIFY = 'AUTH_CLEAR_CONTACT_VERIFY';
export const AUTH_CLOST_ACCOUNT = 'AUTH_CLOST_ACCOUNT';
export const MASTER_DOCTOR_PREFIX = 'MASTER_DOCTOR_PREFIX';
export const MASTER_GROUP_SPECIALTIES = 'MASTER_GROUP_SPECIALTIES';
export const MASTER_TAGS_AND_EVENTS = 'MASTER_TAGS_AND_EVENTS';
export const MASTER_APPS = 'MASTER_APPS';
export const MASTER_MANUALS = 'MASTER_MANUALS';
export const MASTER_FAQS = 'MASTER_FAQS';
export const MASTER_SYMPTOM = 'MASTER_SYMPTOM';
export const MASTER_ORGAN = 'MASTER_ORGAN';
export const MASTER_LANG = 'MASTER_LANG';
export const MASTER_DEACTIVATION_REASON = 'MASTER_DEACTIVATION_REASON';
export const MASTER_CANCEL_BOOKING_REASON = 'MASTER_CANCEL_BOOKING_REASON';
export const DOCTOR_LIST = 'DOCTOR_LIST';
export const DOCTOR_LIST_RECOMMEND = 'DOCTOR_LIST_RECOMMEND';
export const DOCTOR_LIST_FAVORITE = 'DOCTOR_LIST_FAVORITE';
export const DOCTOR_GET_BY_CODE = 'DOCTOR_GET_BY_CODE';
export const DOCTOR_CHECK_ONLINE = 'DOCTOR_CHECK_ONLINE';
export const DOCTOR_CLEAR = 'DOCTOR_CLEAR';
export const PACKAGE_LIST = 'PACKAGE_LIST';
export const HOSPITAL_LIST = 'HOSPITAL_LIST';
export const REFERRAL_GET_BY_CODE = 'REFERRAL_GET_BY_CODE';
export const REFERRAL_GET_BY_CODE_NAV = 'REFERRAL_GET_BY_CODE_NAV';
export const REFERRAL_GET_BY_CODE_CHAT = 'REFERRAL_GET_BY_CODE_CHAT';
export const REFERRAL_GET_REVIEW = 'REFERRAL_GET_REVIEW';
export const REFERRAL_GET_HISTORY = 'REFERRAL_GET_HISTORY';
export const REFERRAL_GET_DRUG_HISTORY = 'REFERRAL_GET_DRUG_HISTORY';
export const REFERRAL_GET_APPOINTMENT = 'REFERRAL_GET_APPOINTMENT';
export const REFERRAL_GET_DOCTOR_BOOKING = 'REFERRAL_GET_DOCTOR_BOOKING';
export const REFERRAL_CHECK_BOOKING = 'REFERRAL_CHECK_BOOKING';
export const REFERRAL_BOOKING = 'REFERRAL_BOOKING';
export const REFERRAL_UPDATE = 'REFERRAL_UPDATE';
export const REFERRAL_CANCEL_BOOKING = 'REFERRAL_CANCEL_BOOKING';
export const REFERRAL_CHECK_BOOKING_AVAILABLE = 'REFERRAL_CHECK_BOOKING_AVAILABLE';
export const REFERRAL_CLEAR = 'REFERRAL_CLEAR';
export const REFERRAL_CHAT_CLEAR = 'REFERRAL_CHAT_CLEAR';
export const BANNER_CLEAR = 'BANNER_CLEAR';
export const BANNER_LIST = 'BANNER_LIST';
export const REALTIME_DATA = 'REALTIME_DATA';
export const SHORT_LINK = 'SHORT_LINK';
export const COUPON_CHECK = 'COUPON_CHECK';
export const COUPON_USE = 'COUPON_USE';
export const COUPON_GET_BY_CODE = 'COUPON_GET_BY_CODE';
export const COUPON_SEARCH_BY_CODE = 'COUPON_SEARCH_BY_CODE';
export const PATIENT_GET_BY_CODE = 'PATIENT_GET_BY_CODE';
export const PATIENT_SEARCH = 'PATIENT_SEARCH';
export const PATIENT_CREATE = 'PATIENT_CREATE';
export const PATIENT_UPDATE = 'PATIENT_UPDATE';
export const PATIENT_CLEAR = 'PATIENT_CLEAR';
export const PATIENT_GET_HEALTH_RECORD = 'PATIENT_GET_HEALTH_RECORD';
export const PATIENT_GET_DOCUMENT = 'PATIENT_GET_DOCUMENT';
export const FEEDBACK_SEND = 'FEEDBACK_SEND';
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const INSURANCE_LIST = 'INSURANCE_LIST';
export const INSURANCE_CIGNA_CHECK_BENEFIT = 'INSURANCE_CIGNA_CHECK_BENEFIT';
export const INSURANCE_SSO_CHECK_BENEFIT = 'INSURANCE_SSO_CHECK_BENEFIT';
export const INSURANCE_MTL_GET_CONSENT = 'INSURANCE_MTL_GET_CONSENT';
export const INSURANCE_MTL_CHECK_BENEFIT = 'INSURANCE_MTL_CHECK_BENEFIT';
export const INSURANCE_CLEAR_BENEFIT = 'INSURANCE_CLEAR_BENEFIT';
export const CHAT_LIST = 'CHAT_LIST';
export const CHAT_SEND_MESSAGE = 'CHAT_SEND_MESSAGE';
export const DRUG_DROP_POINT_LIST = 'DRUG_DROP_POINT_LIST';
export const DRUG_CREATE_ORDER = 'DRUG_CREATE_ORDER';
export const DRUG_CLEAR = 'DRUG_CLEAR';
export const MASTER_LINE_OWNER_CODE = 'MASTER_LINE_OWNER_CODE';
export const REALTIME_SIGNAL = 'REALTIME_SIGNAL';
export const NOTIFICATION_SEND_LINE_MESSAGE = 'NOTIFICATION_SEND_LINE_MESSAGE';
export const REALTIME_SIGNAL_DOCTOR_ONLINE = 'REALTIME_SIGNAL_DOCTOR_ONLINE';
export const REALTIME_SIGNAL_UPDATE = 'REALTIME_SIGNAL_UPDATE';
export const WHITELABEL_SETTING = 'WHITELABEL_SETTING';
export const CHAT_UPDATE = 'CHAT_UPDATE';
export const CHAT_BY_PATIENT_LIST = 'CHAT_BY_PATIENT_LIST';
export const REFERRAL_GET_TODAY = 'REFERRAL_GET_TODAY';
export const WHITELABEL_SETTING_THEME = 'WHITELABEL_SETTING_THEME';
export const WHITELABEL_SETTING_OPTION = 'WHITELABEL_SETTING_OPTION';
export const MASTER_CAMPAIGNS = 'MASTER_CAMPAIGNS';
export const AUTH_GET_FAMILY = 'AUTH_GET_FAMILY';
export const ARTICLE_LIST = 'ARTICLE_LIST';
export const ARTICLE_CLEAR = 'ARTICLE_CLEAR';
export const ARTICLE_DETAIL = 'ARTICLE_DETAIL';