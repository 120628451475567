import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, MASTER_DOCTOR_PREFIX, MASTER_GROUP_SPECIALTIES, MASTER_TAGS_AND_EVENTS, MASTER_APPS, MASTER_MANUALS, MASTER_FAQS, MASTER_SYMPTOM, MASTER_ORGAN, MASTER_LANG, MASTER_DEACTIVATION_REASON, MASTER_CANCEL_BOOKING_REASON, MASTER_LINE_OWNER_CODE, MASTER_CAMPAIGNS } from './types'

export const masterDoctorPrefix = () => async (dispatch) => {
	//hide loading
	const cacheData = localStorage.getItem('cacheDoctorPrefix');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_DOCTOR_PREFIX, playload: { doctorPrefixs: JSON.parse(cacheData) } });
	} else {
		let url = 'master/doctorPrefix';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_DOCTOR_PREFIX: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheDoctorPrefix', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_DOCTOR_PREFIX, playload: { doctorPrefixs: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_DOCTOR_PREFIX: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_DOCTOR_PREFIX: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterGroupSpecialties = () => async (dispatch) => {
	//hide loading
	const cacheData = localStorage.getItem('cacheGroupSpecialtys');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_LANG, playload: { groupSpecialties: JSON.parse(cacheData).filter((o)=>{
			return _.has(o,'code') && _.has(o,'useInApps');
		}) } });
	} else {
		let url = 'master/groupSpecialtys';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_GROUP_SPECIALTIES: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheGroupSpecialtys', JSON.stringify(response.data.data.filter((o)=>{
					return _.has(o,'code') && _.has(o,'useInApps');
				})));
			}
			dispatch({ type: MASTER_GROUP_SPECIALTIES, playload: { groupSpecialties: _.has(response.data, 'data') ? response.data.data.filter((o)=>{
				return _.has(o,'code') && _.has(o,'useInApps');
			}) : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_GROUP_SPECIALTIES: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_GROUP_SPECIALTIES: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterTagsAndEvents = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheTagsAndEvents');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_TAGS_AND_EVENTS, playload: { tagsAndEvents: JSON.parse(cacheData) } });
	} else {
		let url = 'master/tagsAndEvents?onPeriod=1';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_TAGS_AND_EVENTS: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheTagsAndEvents', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_TAGS_AND_EVENTS, playload: { tagsAndEvents: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_TAGS_AND_EVENTS: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_TAGS_AND_EVENTS: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterApps = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheAps');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_APPS, playload: { apps: JSON.parse(cacheData) } });
	} else {
		let url = 'master/apps';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_APPS: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheAps', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_APPS, playload: { apps: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_APPS: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_APPS: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterManuals = () => async (dispatch) => {
  //hide loading
	let url = 'master/manuals';
	const cacheData = localStorage.getItem('cacheManuals');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_MANUALS, playload: { manuals: JSON.parse(cacheData) } });
	} else {
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_MANUALS: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheManuals', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_MANUALS, playload: { manuals: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_MANUALS: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_MANUALS: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterFAQs = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheFaqs');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_FAQS, playload: { faqs: JSON.parse(cacheData) } });
	} else {
		let url = 'master/faqs';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_FAQS: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheFaqs', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_FAQS, playload: { faqs: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_FAQS: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_FAQS: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterSymptom = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheSymptom');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_SYMPTOM, playload: { symptoms: JSON.parse(cacheData) } });
	} else {
		let url = 'master/symptom';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_SYMPTOM: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheSymptom', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_SYMPTOM, playload: { symptoms: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_SYMPTOM: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_SYMPTOM: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterOrgan = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheOrgan');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_ORGAN, playload: { organs: JSON.parse(cacheData) } });
	} else {
		let url = 'master/organ';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_ORGAN: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheOrgan', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_ORGAN, playload: { organs: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_ORGAN: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_ORGAN: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterLang = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheLang');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_LANG, playload: { langs: JSON.parse(cacheData) } });
	} else {
		let url = 'master/lang';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_LANG: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheLang', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_LANG, playload: { langs: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_LANG: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_LANG: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterDeactivationReason = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheDeactivationReason');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_DEACTIVATION_REASON, playload: { deactivateReasons: JSON.parse(cacheData) } });
	} else {
		let url = 'master/deactivationReason';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_DEACTIVATION_REASON: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheDeactivationReason', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_DEACTIVATION_REASON, playload: { deactivateReasons: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_DEACTIVATION_REASON: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_DEACTIVATION_REASON: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterCancelBookingReason = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheCancelBookingReasons');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_CANCEL_BOOKING_REASON, playload: { cancelBookingReasons: JSON.parse(cacheData) } });
	} else {
		let url = 'master/cancelReason';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_CANCEL_BOOKING_REASON: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheCancelBookingReasons', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_CANCEL_BOOKING_REASON, playload: { cancelBookingReasons: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_CANCEL_BOOKING_REASON: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_CANCEL_BOOKING_REASON: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterLineOwnerCode = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheLineOwnerCode');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_LINE_OWNER_CODE, playload: { lineOwnerCode: JSON.parse(cacheData) } });
	} else {
		let url = 'master/lineOwnerCode';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_LINE_OWNER_CODE: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheLineOwnerCode', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_LINE_OWNER_CODE, playload: { lineOwnerCode: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_LINE_OWNER_CODE: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_LINE_OWNER_CODE: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const masterCampaigns = () => async (dispatch) => {
  //hide loading
	const cacheData = localStorage.getItem('cacheCampaigns');
	if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
		dispatch({ type: MASTER_CAMPAIGNS, playload: { campaigns: JSON.parse(cacheData) } });
	} else {
		let url = 'master/campaigns';
		dispatch({ type: IS_LOADING, status: false, names: { MASTER_CAMPAIGNS: false } });
		await axios.get(url).then(response => {
			if(_.has(response.data, 'data') && _.isArray(response.data.data)){
				localStorage.setItem('cacheCampaigns', JSON.stringify(response.data.data));
			}
			dispatch({ type: MASTER_CAMPAIGNS, playload: { campaigns: _.has(response.data, 'data') ? response.data.data : [] } });
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_CAMPAIGNS: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { MASTER_CAMPAIGNS: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}

export const getMasterWhitelabel = async () => {
	return new Promise((reslove) => {
		let url = '/master/whitelabelPatient';
		const cacheData = localStorage.getItem('cacheWhitelabelPatient');
		if(cacheData && JSON.parse(cacheData) && _.isArray(JSON.parse(cacheData))){
			reslove(JSON.parse(cacheData));
		} else {
			axios.get(url).then(response => {
				if(_.has(response, 'data') && _.isArray(response.data)){
					localStorage.setItem('cacheWhitelabelPatient', JSON.stringify(response.data));
				}
				reslove(response.data);
			}).catch(error => {
				reslove({});
			});
		}
	});
}

export const postUserTracking = async (data) => {
	const cacheData = localStorage.getItem(`${data.pathname}${data.userCode}${data.userRole}`);
	if(!cacheData){
		localStorage.setItem(`${data.pathname}${data.userCode}${data.userRole}`, data.userRole);
		let url = '/usertracking';
		axios.post(url,data);
	}
}