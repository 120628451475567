import { COUPON_CHECK, COUPON_USE, COUPON_GET_BY_CODE, COUPON_SEARCH_BY_CODE } from './../actions/types';

const initialState = {
	data: {},
	useCoupon: {},
	searchCoupon: {},
	myCoupons: []
};

export default function CouponReducer(state = initialState, action) {

	switch (action.type) {
		case COUPON_GET_BY_CODE:
			return { ...state, ...action.playload };
		case COUPON_SEARCH_BY_CODE:
			return { ...state, ...action.playload };
		case COUPON_CHECK:
			return { ...state, ...action.playload };
		case COUPON_USE:
			return { ...state, ...action.playload };
		default:
			return { ...state };
	}
}
