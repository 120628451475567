import ImageLoding from './../assets/images/image-1.svg';
import imgCounting from './../assets/images/counting.gif';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
let loopCheckTime = null;
let loopCountdown = null;
let count = 0;
let maxShow = 3;
const Loading = (props) => {
	const { t } = useTranslation();
	const [times, setTime] = useState(0);
	const [counddown, setCounddown] = useState(5);

	useEffect(() => {
		count = 0;
		clearInterval(loopCheckTime);
		clearInterval(loopCountdown);
		loopCheckTime = setInterval(() => {
			count++;
			setTime(count);
			if(count >= maxShow){
				clearInterval(loopCheckTime);
				let indexCounddown = 5;
				loopCountdown = setInterval(() => {
					if(indexCounddown>0){
						indexCounddown -= 1;
						setCounddown(preCounddown => preCounddown - 1);
					}else{
						clearInterval(loopCountdown);
					}
				}, 1000);
			}
		}, 1000);
	}, []);

	return (
		<section className="loading">
			<div className="loading-block">
				<div className="loading-progress"></div>
				{(times >= maxShow && counddown > 0) && (
					<div className='box-image-loading'>
						<div className="doctor-loading">
							<div><img className="image-loading" src={ImageLoding} alt="loading"/></div>
							<div className="time-number">{counddown}</div>
							<div className="text-success">{t('Just a moment')}</div>
							<div><img className="image-process" src={imgCounting} alt="loading-process"/></div>
						</div>
					</div>
				)}
			</div>
		</section>
	);
}
export default Loading;