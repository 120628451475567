import { DRUG_DROP_POINT_LIST, DRUG_CREATE_ORDER, DRUG_CLEAR } from './../actions/types';

const initialState = {
	data: {},
	dataDroppointAll: []
};

export default function DrugReducer(state = initialState, action) {

	switch (action.type) {
		case DRUG_DROP_POINT_LIST:
			return { ...state, ...action.playload };
		case DRUG_CREATE_ORDER:
			return { ...state, ...action.playload };
		case DRUG_CLEAR:
			return { ...state, isCreateOrder: undefined };
		default:
			return { ...state };
	}
};