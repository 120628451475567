import axios from 'axios'
import Bugsnag from '@bugsnag/js'
import _ from 'lodash'
import { IS_LOADING, NOTIFICATION_LIST,NOTIFICATION_SEND_LINE_MESSAGE } from './types'

export const notificationList = (patCode) => async (dispatch) => {
	//hide loading
	let url = `/notifications?patCode=${patCode}&limit=100`;
	dispatch({ type: IS_LOADING, status: true, names: { NOTIFICATION_LIST: false } });
	await axios.get(url).then(response => {
		dispatch({ type: NOTIFICATION_LIST, playload: { data: _.has(response.data, 'data') ? response.data.data : [] } });
		dispatch({ type: IS_LOADING, status: false, names: { NOTIFICATION_LIST: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { NOTIFICATION_LIST: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const notificationLine = (params) => async (dispatch) => {
	//hide loading
	let url = `/notifications/byReferralRefCode`;
	dispatch({ type: IS_LOADING, status: true, names: { NOTIFICATION_LINE: false } });
	await axios.post(url, params).then(() => {
		dispatch({ type: IS_LOADING, status: false, names: { NOTIFICATION_LINE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { NOTIFICATION_LINE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const notificationUpdate = (id, params) => async (dispatch) => {
	//hide loading
	let url = `/notifications/${id}`;
	dispatch({ type: IS_LOADING, status: true, names: { NOTIFICATION_UPDATE: false } });
	await axios.put(url, params).then(() => {
		dispatch({ type: IS_LOADING, status: false, names: { NOTIFICATION_UPDATE: false } });
	}).catch(error => {
		dispatch({ type: IS_LOADING, status: false, names: { NOTIFICATION_UPDATE: false } });
		if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
			if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
			Bugsnag.notify(error);
		}
	});
}

export const sendLineMessage = (params) => {
	//hide loading
	let url = `/v1/line/lineMessageByUserId`;
	return dispatch => {
		dispatch({ type: IS_LOADING, status: true, names: { NOTIFICATION_SEND_LINE_MESSAGE: false } });
		axios.post(url, params).then(response => {
			const resData = response.data;
			let data = resData;
			dispatch({ type: NOTIFICATION_SEND_LINE_MESSAGE, playload: data });
			dispatch({ type: IS_LOADING, status: false, names: { NOTIFICATION_SEND_LINE_MESSAGE: false } });
		}).catch(error => {
			dispatch({ type: IS_LOADING, status: false, names: { NOTIFICATION_SEND_LINE_MESSAGE: false } });
			if ((_.has(error, 'response.status') && error.response.status === 502) || _.isUndefined(error.response)) {
				if (_.isUndefined(error.response)) { error = Error('502 API at ' + url); }
				Bugsnag.notify(error);
			}
		});
	}
}