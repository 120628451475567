import { REALTIME_DATA, REALTIME_SIGNAL, REALTIME_SIGNAL_DOCTOR_ONLINE, REALTIME_SIGNAL_UPDATE } from './types';

export const updateRealtime = (name, status, data = {}) => {
	return dispatch => {
		dispatch({ type: REALTIME_DATA, playload: { name: name, status: status, data: data } });
	}
}

export const updateRealtimeSignal = (status) => {
	return dispatch => {
		dispatch({ type: REALTIME_SIGNAL, playload: { signalStatus: status } });
	}
}

export const updateRealtimeSignalDoctorOnline = (doctors) => {
	return dispatch => {
		dispatch({ type: REALTIME_SIGNAL_DOCTOR_ONLINE, playload: { doctors: doctors } });
	}
}

export const updateRealtimeSignalDataOnline = (updateSignal) => {
	return dispatch => {
		dispatch({ type: REALTIME_SIGNAL_UPDATE, playload: { updateSignal: updateSignal } });
	}
}